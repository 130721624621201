import React, { useState, useEffect } from 'react';
import { ProgressBar, Form, Card, ButtonGroup, Button, Spinner, Toast } from 'react-bootstrap';
import '../styles/grid.css';
import { NavSidebar } from './components/NavSidebar';
import { useAuth } from '../context/AuthContext';
import moment from 'moment';
import Company from "../models/Company";
import Property from '../models/Property';
import View from '../models/View';

import Check from "../models/Check";
import Utils from "../common/Utils";
import _ from 'lodash';
import {MdArrowBack} from 'react-icons/md';

import {
  BrowserRouter as Router,
  useLocation, 
  useHistory
} from "react-router-dom";

const MetricsScreen = () => {

  const {
    user,
    isAdminOrHigher
  } = useAuth();
  const history = useHistory();


  const [showToast, setShowToast] = useState(false); 
  const [targets, setTargets] = useState({
    'Month to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
    'Quarter to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
    'Year to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
  });


  const [isLoading, setIsLoading] = useState(true);
  const [period, setPeriod] = useState('Month to Date');
  const [isCitySeg, setIsCitySeg] = useState(false);


  const [metricsData, setMetricsData] = useState({
    'Month to Date': { inspections: 0, checks: 0, devices: 0, uniqueUsers: 0 },
    'Quarter to Date': { inspections: 0, checks: 0, devices: 0, uniqueUsers: 0 },
    'Year to Date': { inspections: 0, checks: 0, devices: 0, uniqueUsers: 0 },
  });

  const encodeTargets = (targetsObj) => JSON.stringify(targetsObj);

  const decodeTargets = (targetsStr) => {
    try {
      return JSON.parse(targetsStr);
    } catch (error) {
      return {
        'Month to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
        'Quarter to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
        'Year to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
      };
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchCompanyTargets = async () => {
      const companyArray = await Company.searchByCompanyUuid(user?.company?.uuid);
      const company = companyArray?.length > 0 ? companyArray[0] : null;
      if (company && company?.company_targets) {
        setTargets(decodeTargets(company?.company_targets));
      } else {
        setTargets({
          'Month to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
          'Quarter to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
          'Year to Date': { inspections: 0, checks: 0, devices: 0, users: 0 },
        });
      }

    };

    fetchCompanyTargets();
  }, [user.id, user.company.uuid]);



  const updateTargets = (period, type, value) => {
    const updatedTargets = { ...targets, [period]: { ...targets[period], [type]: value } };
    setTargets(updatedTargets);
  };

  const handleSubmit = async () => {
    const encodedTargets = encodeTargets(targets);
    await Company.update({
      uuid: user.company.uuid,
      company_targets: encodedTargets
    });
    setShowToast(true); // Show the toast upon successful submission
  };

  // const calculateDateRange = (period) => {
  //   let start, end = moment().endOf('day');
  //   switch (period) {
  //     case 'Month to Date':
  //       start = moment().startOf('month');
  //       break;
  //     case 'Quarter to Date':
  //       start = moment().startOf('quarter');
  //       break;
  //     case 'Year to Date':
  //       start = moment().startOf('year');
  //       break;
  //     default:
  //       start = moment().startOf('month');
  //   }

  //   return { start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") };
  // };



  
//   const fetchPropertyActivity = async (start, end) => {
//     let propertiesInCompany;
//     const data = await View.getViewMetricsAtCompany(user.company.id);
//     const data2 = await View.getViewUserMetricsAtCompany(user.company.id);

//     console.log("This is the METRICS data for the company", data);
//     console.log("This is the USERS data for the company", data2);


//     if (user.company.uuid === "58774973-ac3d-482f-8551-29d3276e97cc") {
//         console.log("IN CITY-SEG for metrics screen");
//         propertiesInCompany = await Company.detailedFilteredPropertyActivityCitySeg(
//             user.id,
//             user.company.uuid,
//             null,
//             null,
//             start,
//             end
//         );
//     } else {
//         console.log("NOT IN CITY-SEG for metrics screen");

//         propertiesInCompany = await Company.detailedFilteredPropertyActivity(
//             user.id,
//             user.company.uuid,
//             null,
//             null,
//             start,
//             end
//         );
//     }

//     const allProperties = propertiesInCompany?.data?.flatMap(company => company?.propertiesFilter);
 
//     return allProperties?.filter(property => property !== null);
// };


// const calculateMetricsFromData = async (data, startDate, endDate) => {
//   let inspections = 0, uniqueUsers = new Set(), totalChecks = 0;
//   let uniqueDeviceUnitDecs = new Set(); // Initialize a Set to keep all unique unit_dec values across all properties

//   // Initialize a Set to keep all unique task UUIDs
//   let allTaskUUIDs = new Set();
//   // Initialize an object to map fig UUIDs to their corresponding task UUIDs
//   let figToTaskUUIDsMap = {};

//   // Iterate through each property to collect task UUIDs and map them by fig UUIDs
//   data?.forEach(property => {
//     property?.figs?.forEach(fig => {
//       if (fig?.sheets?.length > 0) {
//         const sheet = fig?.sheets[0]; // Assuming checks relate to the first sheet
//         const sectionTaskUUIDs = sheet?.sections?.flatMap(section => section?.tasks?.map(task => task?.uuid?.toString())) ?? [];
//         const taskUUIDs = sheet?.tasks?.map(task => task?.uuid?.toString()) ?? [];
//         const uniqueTaskUUIDs = [...new Set([...sectionTaskUUIDs, ...taskUUIDs])]; // Ensure uniqueness
//         figToTaskUUIDsMap[fig?.uuid?.toString()] = uniqueTaskUUIDs; // Map fig UUID to task UUIDs
//         uniqueTaskUUIDs.forEach(uuid => allTaskUUIDs.add(uuid)); // Collect all unique task UUIDs
//       }
//     });
//   });

//   let fetchedChecks = [];
//   if (allTaskUUIDs.size > 0) {
//     // Fetch checks for all unique task UUIDs in one go
//     const response = await Check.filterBy([
//       { field: 'task_uuid', value: JSON.stringify([...allTaskUUIDs]), comparison: "IN" },
//       { field: "archived", value: 0, comparison: "=" }
//     ], "created_at", "desc", 1000);
//     fetchedChecks = response?.data ?? [];
//   }

//   // Calculate MSI Inspections, Devices, and Unique Users as before
//   data?.forEach(property => {
//     if (property?.vals) {
//       const equipmentCounts = _.groupBy(property?.vals?.edges, edge => edge?.node?.point_num);
  
//       inspections += Object.keys(equipmentCounts).length;
      
//    // Instead of calculating device counts here, add each unit_dec value to the Set
//    property?.vals?.edges.forEach(edge => {
//     if(edge?.node?.wav?.unit_dec) {
//       uniqueDeviceUnitDecs.add(edge?.node?.wav?.unit_dec);
//     }
//   });
//     }

//     const userIDsFromVals = property?.vals?.edges
//   ?.map(edge => edge?.node?.user?.id)
//   .filter(id => id != null) ?? []; // Filters out null or undefined values and ensures an array is always returned

// const userIDsFromChecks = property?.checks?.edges
//   ?.map(edge => edge?.node?.user?.id)
//   .filter(id => id != null) ?? []; // Filters out null or undefined values and ensures an array is always returned

// if (property?.last_evaluation_user_id) {
//   userIDsFromVals.push(property?.last_evaluation_user_id.toString());
// }

// new Set([...userIDsFromVals, ...userIDsFromChecks].filter(id => id)).forEach(id => uniqueUsers.add(id.toString()));

  
//   });

//   Object.entries(figToTaskUUIDsMap).forEach(([figUUID, taskUUIDs]) => {
//     let figChecks = fetchedChecks.filter(check => 
//       taskUUIDs.includes(check?.task_uuid?.toString()) && check?.fig_uuid?.toString() === figUUID
//       && moment.tz(check?.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), moment(endDate).add(1, 'day'))
//     );
//     totalChecks += figChecks.length;
//   });

//   let devices = uniqueDeviceUnitDecs.size;

//   console.log("Unique Devices", Array.from(uniqueDeviceUnitDecs));
//   console.log("UniqueUsers", uniqueUsers);
  


//   return {
//     inspections,
//     devices,
//     checks: totalChecks,
//     uniqueUsers: uniqueUsers.size,
//   };
// };



  // // Function to fetch and calculate metrics for all periods (Monthly, Quarterly, Yearly)
  // const fetchAndCalculateMetrics = async () => {
  //   setIsLoading(true);
  //   const periods = ['Month to Date', 'Quarter to Date', 'Year to Date'];
  //   let newMetricsData = { ...metricsData };
  
  //   for (let period of periods) {
  //     const { start, end } = calculateDateRange(period);
  //     // Fetching data based on the start and end dates
  //     const data = await fetchPropertyActivity(start, end);
  //     // Calculate metrics from the fetched data
  //     const metrics = await calculateMetricsFromData(data, start, end); 
  //     // Directly using the returned metrics to update the newMetricsData object for the current period
  //     newMetricsData[period] = metrics;
  
  //   }
  
  //   setMetricsData(newMetricsData);
  //   setIsLoading(false); 
  // };
  
  const fetchAndCalculateMetrics = async () => {
    setIsLoading(true);
  
    // Fetch metrics and user metrics data
    const metricsDataResponse = await View.getViewMetricsAtCompany(user.company.id);
    console.log("metricsDataResponse: ", metricsDataResponse);
    const userMetricsDataResponse = await View.getViewUserMetricsAtCompany(user.company.id);
    console.log("userMetricsDataResponse: ", userMetricsDataResponse);

    // Assuming both methods return an array with one element which is the object containing the metrics
    const metrics = metricsDataResponse?.[0] || {};
    const userMetrics = userMetricsDataResponse?.[0] || {};
    const newMetricsData = {
      'Month to Date': {
        inspections: metrics.inspectionMonthCount,
        checks: metrics.checksMonthCount,
        devices: metrics.deviceMonthCount,
        uniqueUsers: userMetrics.usersMonthCount
      },
      'Quarter to Date': {
        inspections: metrics.inspectionQuarterCount,
        checks: metrics.checksQuarterCount,
        devices: metrics.deviceQuarterCount,
        uniqueUsers: userMetrics.usersQuarterCount
      },
      'Year to Date': {
        inspections: metrics.inspectionYearCount,
        checks: metrics.checksYearCount,
        devices: metrics.deviceYearCount,
        uniqueUsers: userMetrics.usersYearCount || 0 
      }
    };
  
    setMetricsData(newMetricsData);
    setIsLoading(false);
  };
  
  

  useEffect(() => {
    fetchAndCalculateMetrics();
  }, [user.id, user.company.uuid]); // Fetch metrics when component mounts or user changes




   
 // UI for selecting the period
 const renderPeriodSelector = () => (
  <ButtonGroup className="mb-3">
    {['Month to Date', 'Quarter to Date', 'Year to Date'].map(p => (
      <Button key={p} variant={period === p ? 'primary' : 'secondary'} onClick={() => setPeriod(p)}>
        {p}
      </Button>
    ))}
  </ButtonGroup>
);
const downloadMetricsCsv = () => {
  // Define the headers for the CSV
  const headers = ['Period', 'Inspections', 'Inspections Target', 'Inspections Completion (%)', 'Checks', 'Checks Target', 'Checks Completion (%)', 'Devices', 'Devices Target', 'Devices Completion (%)', 'Unique Users', 'Unique Users Target', 'Unique Users Completion (%)'];

  // Explicitly define the sequence of metrics to ensure alignment
  const metricsSequence = ['inspections', 'checks', 'devices', 'uniqueUsers'];

  const periods = ['Month to Date', 'Quarter to Date', 'Year to Date'];

  // Map each period to its row in the CSV
  const rows = periods.map(period => {
    const rowData = [period]; // Start the row with the period name

    metricsSequence.forEach(metricKey => {
      const currentValue = metricsData[period][metricKey];
      // Adjust the targetKey for 'uniqueUsers' to 'users' when necessary
      const targetKey = metricKey === 'uniqueUsers' ? 'users' : metricKey;
      const targetValue = targets[period][targetKey] || 0;
      const completionPercentage = targetValue > 0 ? (currentValue / targetValue) * 100 : 0;
      const completion = targetValue > 0 ? `${completionPercentage.toFixed(2)}%` : 'N/A';

      // Append the current value, target, and completion to the row data
      rowData.push(currentValue, targetValue, completion);
    });

    // Join the row data with commas to create a CSV row
    return rowData.join(',');
  });

  // Combine the headers and rows, then join them with new lines to form the CSV content
  const csvContent = [headers.join(','), ...rows].join('\n');

  // Create a blob with the CSV content and trigger a download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', 'metrics_report.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const goBack = () => {
    history.push(`/migration`);
}
 
if (isLoading) {
  return (
      <div className="d-flex flex-row justify-content-start align-items-start">
          <NavSidebar />
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start', 
              alignItems: 'center',
              height: '100vh',
              width: '100%',
              paddingTop: '30vh', 
              paddingRight: '30vh', 
              paddingBottom: '20vh'
          }}>
              <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', marginBottom: '20px' }} />
              <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {isCitySeg ? "Hello City-Seg, Calculating Metrics... Please Wait a Second" : "Calculating Metrics... Please Wait a Second"}
              </div>
              {isCitySeg && (
                  <div style={{ fontSize: '20px', marginTop: '10px' }}>
                      There are many properties, this could take 3-4 minutes, please be patient, thank you!
                  </div>
              )}
          </div>
      </div>
  );
}

  return (
    <div className="d-flex flex-row justify-content-start align-items-start" style={{ width: '100%', height: '100vh' }}>
      
      <NavSidebar />
      <div className="d-flex flex-column" style={{
        flexGrow: 1,
        padding: '20px',
        marginTop: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
        marginLeft: '20px',
        marginRight: '20px'
      }}>
        <div className={`d-flex flex-row flex-no-wrap justify-start align-items-center`}>
          <Button variant="link" onClick={() => {
          goBack();
          }}>
            <MdArrowBack />
          </Button>
          <div className="font-weight-bold">{'Go Back'}</div>
        </div>

        <div style={{
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#4A90E2', 
        margin: '20px 0',
        textAlign: 'center',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#E8F4FA', 
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}>
        Today is {moment().format('dddd, MMMM Do YYYY')}
      </div>

        {renderPeriodSelector()}
        <MetricDisplay
          title="Total MSI Inspections"
          metricKey="inspections"
          targetValue={targets[period].inspections}
          setTargetValue={(value) => updateTargets(period, 'inspections', value)}
          metricsData={metricsData}
          period={period}
          isAdminOrHigher={isAdminOrHigher}
        />
        <MetricDisplay
          title="Total Checks"
          metricKey="checks"
          targetValue={targets[period].checks}
          setTargetValue={(value) => updateTargets(period, 'checks', value)}
          metricsData={metricsData}
          period={period}
          isAdminOrHigher={isAdminOrHigher}
        />
        <MetricDisplay
          title="Total MSI Devices"
          metricKey="devices"
          targetValue={targets[period].devices}
          setTargetValue={(value) => updateTargets(period, 'devices', value)}
          metricsData={metricsData}
          period={period}
          isAdminOrHigher={isAdminOrHigher}
        />
        <MetricDisplay
          title="Total Unique Users"
          metricKey="uniqueUsers"
          targetValue={targets[period].users}
          setTargetValue={(value) => updateTargets(period, 'users', value)}
          metricsData={metricsData}
          period={period}
          isAdminOrHigher={isAdminOrHigher}
        />
<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <Button style={{ width: 'auto', padding: '0 30px' }} onClick={downloadMetricsCsv}>Download Metrics CSV</Button>
          {isAdminOrHigher() && <Button style={{ backgroundColor: '#4CAF50', borderColor: '#4CAF50' }} onClick={handleSubmit}>Save All Targets</Button>}
        </div>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '300px',
  zIndex: 1050,
  backgroundColor: '#f0f0f0', 
  color: '#333', 
  boxShadow: '0 0 15px rgba(0,0,0,0.2)', 
  borderRadius: '8px', 
}}>
  <Toast.Header style={{
    backgroundColor: '#4CAF50', 
    color: '#fff', 
    borderTopLeftRadius: '7px', 
    borderTopRightRadius: '7px', 
    fontWeight: 'bold', 
  }}>
    <strong className="mr-auto">Success!</strong>
  </Toast.Header>
  <Toast.Body style={{
    padding: '16px', 
    fontSize: '16px', 
    backgroundColor: '#ffffff',
  }}>
    Targets updated successfully!
  </Toast.Body>
</Toast>
      </div>
    </div>
  );

};
const calculateProgress = (current, target) => {
  return target > 0 ? (current / target) * 100 : 0;
};
const MetricDisplay = ({ title, metricKey, targetValue, setTargetValue, metricsData, period, isAdminOrHigher }) => {
  const currentMetricValue = metricsData[period][metricKey];

  const targetTitles = {
    inspections: "Target MSI Inspections",
    checks: "Target Checks",
    devices: "Target MSI Devices",
    uniqueUsers: "Target Unique Users",
  };

  return (
    <Card className="mb-3" style={{
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      borderColor: '#e0e0e0'
    }}>
      <Card.Body>
        <Card.Title style={{
          fontSize: '20px',
          fontWeight: 'bold',
          marginBottom: '15px'
        }}>{title}</Card.Title>
        <div className="d-flex justify-content-between align-items-center">
          <div style={{
            fontSize: '16px',
            fontWeight: '500'
          }}>{`${currentMetricValue}`}</div>
          <ProgressBar
            now={calculateProgress(currentMetricValue, targetValue)}
            label={`${calculateProgress(currentMetricValue, targetValue).toFixed(0)}%`}
            className="mx-2 flex-grow-1"
            style={{
              height: '20px',
              backgroundColor: '#f5f5f5',
              borderRadius: '5px',
            }}
          />
          <div style={{ minWidth: '150px', textAlign: 'center' }}>
            <div style={{
              marginBottom: '5px', 
              fontSize: '15px', 
            }}>{targetTitles[metricKey]}</div>
            <Form.Control
              type="number"
              value={targetValue}
              onChange={(e) => setTargetValue(Number(e.target.value))}
              style={{
                width: '100%',
                textAlign: 'center',
              }}
              disabled={!isAdminOrHigher()} 
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};






export default MetricsScreen;
